<template>
  <!-- 商标详情页 -->
  <div style="position: relative">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />

    <!-- 商标详情信息头部 -->
    <div class="TradDeatails">
      <div class="content">
        <div class="TradDeatailsHeader">
          <div class="picture">
            <img class="imger" alt="" :src="tranData.tii_image" />
          </div>
          <div class="deatailsHeader">
            <p class="title font">{{ tranData.tii_name }}</p>
            <p class="title">
              <StateDiv :word="tranData.tii_status" :none="none" />
            </p>

            <p>
              <span class="word01"
                >注册号：<span class="word02">{{
                  tranData.tii_reg_id
                }}</span></span
              >
              <span class="word01"
                >申请日期：<span class="word02">{{
                  tranData.tii_register_date
                }}</span></span
              >
              <span class="word01"
                >国际分类：<span class="word02"
                  >第{{ tranData.tii_internation_type }}类</span
                ></span
              >
            </p>
          </div>
        </div>

        <div class="TradDeatailsContent">
          <p class="infotitle">商标信息</p>
          <div class="TradDeatailsContentInfo">
            <!-- 以下是信息栏的信息条 每个infoTubiao为一行 -->
            <div class="infoTubiao">
              <p class="infoTubiaotit">商标图案</p>
              <div class="picture">
                <div class="tubiao">
                  <img class="imger" :alt="tranData.tii_name" :src="tranData.tii_image" />
                </div>
              </div>
            </div>
            <hr />

            <div class="infoTubiao">
              <p class="infoTubiaotit">申请人</p>
              <div class="applicantcopy">
                {{ tranData.tii_register_cn }}
                <button class="applicantBox" @click="check()">企业详情</button>
              </div>
              <p class="applicant">申请人地址</p>
              <p class="applicantlast">
                {{ tranData.tii_register_address_cn }}
              </p>
            </div>
            <hr />

            <div class="infoTubiao">
              <p class="infoTubiaotit">代理机构</p>
              <div class="applicantcopy">{{ tranData.tii_agency }}</div>
              <p class="applicant">商标申请日期</p>
              <p class="applicantlast">{{ tranData.tii_register_date }}</p>
            </div>
            <hr />

            <div class="infoTubiao">
              <p class="infoTubiaotit">初审公告时间</p>
              <div class="applicantirr">{{ tranData.tii_trial_date }}</div>
              <p class="applicant">注册公告时间</p>
              <p class="applicantlast">{{ tranData.tii_notice_date }}</p>
            </div>
            <hr />

            <div class="infoTubiao">
              <p class="infoTubiaotit">专属权限期</p>
              <div class="applicantirr">
                {{ tranData.tii_dedicatd_start }} 至
                {{ tranData.tii_dedicatd_end }}
              </div>
              <p class="applicant">注册满3年日期</p>
              <p class="applicantlast">-</p>
            </div>
            <hr />

            <div class="infoTubiao">
              <p class="infoTubiaotit">期满续展时间</p>
              <div class="applicantirr">-</div>
            </div>
            <hr />

            <div class="infoTubiao">
              <p class="infoTubiaotit">注册的商品/服务</p>
              <div class="tradclass">
                <p class="word05" v-for="(item, index) in name_cn" :key="index">
                  {{ item }}
                </p>

                <p class="word06">3601 - 保险资询</p>
                <p class="word06">3601 - 保险资询</p>
                <p class="word07">3601 - 保险资询</p>
              </div>
            </div>
            <hr />

            <div class="infoTubiao" >
              <p class="infoTubiaotit">商标流程状态</p>
              <div class="tradclass">
                <div class="tradclassbox">
                  <div class="left">
                    <div class="number">3</div>
                    <div class="lan"></div>
                  </div>
                  <div class="right">
                    <p class="date">2020-10-16</p>
                    <p class="date">驳回通知发文</p>
                  </div>
                </div>
                <div class="tradclassbox">
                  <div class="left">
                    <div class="number">2</div>
                    <div class="lan"></div>
                  </div>
                  <div class="right">
                    <p class="date">2020-10-16</p>
                    <p class="date">驳回通知发文</p>
                  </div>
                </div>

                <div class="tradclassbox">
                  <div class="left">
                    <div class="number">1</div>
                    <div class="lan"></div>
                  </div>
                  <div class="right">
                    <p class="date">2020-10-16</p>
                    <p class="date">驳回通知发文</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <!-- 登录弹窗 -->
    <Signin :loginState="siginState" @close="close01" />
    <!-- 企业详情弹窗 -->
    <Check :checkState="checkState" @close="close" />
    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import TradHeader from "../components/trademarkClassification/tradHeader.vue";
import StateDiv from "../components/stateDiv.vue";
import Signin from "../components/trademarkQueryResults/signin.vue";
import Check from "../components/trademarkQueryResults/check.vue";
import { detiail, keyCode, newIP } from "../API/index";

// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'
export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    StateDiv,
    TradHeader,
    Check,
    Signin
  },
  data() {
    return {
      tranData: [],
      gjfl: "",
      name_cn: [],
      none: "black",
      checkState: false,
      ip: "",
      req_id: "",
      req_type: "",
      siginState: false,
    };
  },
  methods: {
    check() {
      this.checkState = true;
    },
    close01(data) {
      this.siginState = data;
      this.$router.push({path:'/'})
    },
    close(data) {
      this.checkState = data;
    },
    async fn1() {
      let userID = JSON.parse(localStorage.getItem("user"));
      let key = sessionStorage.getItem("userkey");
      const res = await detiail({
        key: key,
        phone: userID == null ? "0" : userID.phone,
        user_id: userID == null ? 0 : userID.id,
        reg_id: this.req_id,
        reg_type: this.req_type,
      });
      if (res.data.key) {
          await sessionStorage.setItem("userkey", res.data.key);
        this.tranData = res.data.trademarks;
        res.data.trademarks.tii_name_cn.split("-(/)").forEach((el) => {
          this.name_cn.push(el.replace(";", ""));
          // localStorage.removeItem('reg_id')
          // localStorage.removeItem('reg_type')
        });
      }
    },
    // 获取key
    async keyCode() {
      const resIP = await newIP();
      if (resIP.msg == "success") {
        this.ip = resIP.data;
      }
      let duan = sessionStorage.getItem("key");
      if (duan == null) {
        const res = await keyCode({
          // 获取客户端的ip地址
          ip: this.ip,
          platform: "web",
        });
        await sessionStorage.setItem("key", res.data);
      } else {
      }
    },
  },
  async created() {
    var keys = generatekey();
    const index = window.location.pathname.indexOf('.html')
    const idpass = window.location.pathname.slice(9,index)
    var dess = JSON.parse(decryptDes(idpass,keys));
    this.req_id = dess.id;
    this.req_type = dess.type;
    if (this.req_id == null || this.req_type == null) {
      this.$router.push({ path: "/rearch" });
    }
    let userID = JSON.parse(localStorage.getItem("user"));
    if (userID == null) {
      return (this.siginState = true);
    }
    await this.keyCode();
    await this.fn1();
  },
};
</script>

<style scoped lang="scss">
hr {
  height: 1px;
  background-color: #ecf6ff;
  border: none;
}
.TradDeatails {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  position: relative;
  .content {
    width: 74.375rem /* 1190px -> 74.375rem */;
    .TradDeatailsHeader {
      width: 100%;
      height: 9.8125rem /* 157px -> 9.8125rem */;
      margin-top: 1.4375rem /* 23px -> 1.4375rem */;
      background-color: #fff;
      box-shadow: 0 0 0.625rem /* 10px -> 0.625rem */ 0.3125rem #e9e8e8;
      display: flex;
      align-items: center;
      .picture {
        width: 6.25rem /* 100px -> 6.25rem */;
        height: 6.25rem /* 100px -> 6.25rem */;
        margin-left: 1.6875rem /* 27px -> 1.6875rem */;
        margin-right: 1.6875rem /* 27px -> 1.6875rem */;
        border: 0.0625rem /* 1px -> 0.0625rem */ solid #ebebeb;
        display: flex;
        justify-content: center;
        align-items: center;
        .imger {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .deatailsHeader {
        .title {
          margin-bottom: 1.3125rem /* 21px -> 1.3125rem */;
        }
        .font {
          font-size: 1.375rem /* 22px -> 1.375rem */;
          color: #111111;
          font-weight: bold;
        }
        .word01 {
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #808080;
        }
        .word02 {
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #111111;
          margin-right: 4.375rem /* 70px -> 4.375rem */;
        }
      }
    }
    .TradDeatailsContent {
      width: 100%;
      margin-top: 1.4375rem /* 23px -> 1.4375rem */;
      background-color: #fff;
      box-shadow: 0 0 0.625rem /* 10px -> 0.625rem */ 0.3125rem #e9e8e8;
      margin-bottom: 4.4375rem /* 71px -> 4.4375rem */;
      padding: 1.5rem /* 24px -> 1.5rem */;
      padding-bottom: 3.5625rem /* 57px -> 3.5625rem */;
      box-sizing: border-box;
      .TradDeatailsContentInfo {
        width: 100%;
        margin-top: 2.5625rem /* 41px -> 2.5625rem */;
        display: flex;
        flex-direction: column;
        .infoTubiao {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0.9375rem /* 15px -> 0.9375rem */;
          padding-left: 1.5625rem /* 25px -> 1.5625rem */;
          box-sizing: border-box;
          position: relative;
          .infoTubiaotit {
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #333333;
          }
          .picture {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            .tubiao {
              width: 6.25rem /* 100px -> 6.25rem */;
              height: 6.25rem /* 100px -> 6.25rem */;
              border: 0.0625rem /* 1px -> 0.0625rem */ solid #ebebeb;
              padding: 0.125rem /* 2px -> 0.125rem */;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              .imger{
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
          .applicantcopy {
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #4d89f1;
            position: absolute;
            left: 13.0625rem /* 209px -> 13.0625rem */;
            display: flex;
            align-items: center;
            .applicantBox {
              background: #fff;
              padding-left: 0.3125rem /* 5px -> 0.3125rem */;
              padding-right: 0.3125rem /* 5px -> 0.3125rem */;
              box-sizing: border-box;
              border-radius: 0.3125rem /* 5px -> 0.3125rem */;
              border: 0.0625rem /* 1px -> 0.0625rem */ solid #f89941;
              color: #f89941;
              font-size: 0.696875rem /* 11.15px -> 0.696875rem */;
              margin-left: 0.8125rem /* 13px -> 0.8125rem */;
              text-align: center;
              line-height: 1.39375rem /* 22.3px -> 1.39375rem */;
              transition: 0.3s;
              cursor: pointer;
            }
            .applicantBox:hover {
              background: #f89941;
              color: #fff;
            }
          }
          .applicant {
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #333333;
            position: absolute;
            left: 37rem /* 592px -> 37rem */;
          }
          .applicantirr {
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #333333;
            position: absolute;
            left: 13.0625rem /* 209px -> 13.0625rem */;
            display: flex;
            align-items: center;
          }
          .applicantlast {
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #333333;
            position: absolute;
            left: 51.0625rem /* 817px -> 51.0625rem */;
          }
          .tradclass {
            margin-left: 5rem /* 80px -> 5rem */;
            line-height: 2.3125rem /* 37px -> 2.3125rem */;
            .word05 {
              font-size: 0.875rem /* 14px -> 0.875rem */;
              color: #333333;
            }
            .word06 {
              font-size: 0.875rem /* 14px -> 0.875rem */;
              color: #999999;
            }
            .word07 {
              font-size: 0.875rem /* 14px -> 0.875rem */;
              color: #999999;
              text-decoration-line: line-through;
            }
            .tradclassbox {
              height: 5.3125rem /* 85px -> 5.3125rem */;
              display: flex;
              .left {
                width: 1.875rem /* 30px -> 1.875rem */;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .number {
                  width: 1.625rem /* 26px -> 1.625rem */;
                  height: 1.625rem /* 26px -> 1.625rem */;
                  border-radius: 50%;
                  border: 1px solid #a8a8a8;
                  color: #a8a8a8;
                  font-size: 1.125rem /* 18px -> 1.125rem */;
                  text-align: center;
                  line-height: 1.625rem /* 26px -> 1.625rem */;
                }
                .lan {
                  width: 0.125rem /* 2px -> 0.125rem */;
                  height: 2.6875rem /* 43px -> 2.6875rem */;
                  background-color: #cdcdcd;
                  margin-top: 0.4375rem /* 7px -> 0.4375rem */;
                }
              }
              .right {
                height: 100%;
                margin-left: 0.9375rem /* 15px -> 0.9375rem */;
                .date {
                  font-size: 0.875rem /* 14px -> 0.875rem */;
                  color: #333333;
                  line-height: 1.5625rem /* 25px -> 1.5625rem */;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>