<template>


  <div v-show="login" class="signin">
    <div class="signinBox">
      <div class="signinBoxTop">

        <!-- logo以及微信小程序二维码 -->
        <div class="logoPicture">
          <img src="../../imger/WBWlogo01.png" title="问标网" alt="问标网" class=" signinicon01">
          <img src="../../imger/xcx.png" alt="" class=" signinicon02">
          <p class=" signinword01">为更好体验，请微信扫码访问小程序</p>
        </div>
      </div>

      <!-- 下方的关闭按钮 -->
      <div class="signinBoxClose">
        <img src="../../imger/tranClassGroup/group111.png" alt="" class="signinicon03" @click="close">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['checkState'],

  data(){
    return {
      login:this.checkState,
    }
  },
  watch:{
    checkState(curVal,oldVal){
      if(curVal){
        this.login = curVal
      } 
    }
  },
  methods:{
    close(){
      this.login = false
      this.$emit("close",this.login)
    },
  }
}
</script>

<style scoped lang="scss">
.signin {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: rgba($color: #000000, $alpha: .5);
  .signinBox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 23.75rem /* 380px -> 23.75rem */;
    height: 31.25rem /* 500px -> 31.25rem */;
    display: flex;
    flex-direction: column;
    .signinBoxTop {
      width: 100%;
      height: 24.5625rem /* 393px -> 24.5625rem */;
      display: flex;
      .logoPicture {
        width: 23.75rem /* 380px -> 23.75rem */;
        height: 100%;
        background-color: #EEF7FF;
        border-radius: 0.625rem /* 10px -> 0.625rem */;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .signinicon01 {
          width: 9.4375rem /* 151px -> 9.4375rem */;
        }
        .signinicon02 {
          width: 11.8125rem /* 189px -> 11.8125rem */;
          height: 11.8125rem /* 189px -> 11.8125rem */;
        }
        .signinword01 {
          font-size: 1.25rem /* 20px -> 1.25rem */;
          color: #333333;
        }
      }
    }
    .signinBoxClose {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .signinicon03 {
        width: 2.125rem /* 34px -> 2.125rem */;
        height: 2.125rem /* 34px -> 2.125rem */;
      }
    }
    
  }
}

</style>